import { graphql, useStaticQuery } from 'gatsby';

export function useLifeAtRasaBlogLast3() {
  return useStaticQuery(graphql`
    query useLifeAtRasaBlogLast3 {
      allDatoCmsPost(
        sort: { fields: meta___firstPublishedAt, order: DESC }
        filter: {
          meta: { status: { eq: "published" } }
          categories: { elemMatch: { slug: { eq: "life-at-rasa" } } }
        }
        limit: 3
      ) {
        nodes {
          title
          slug
          excerptNode {
            childMarkdownRemark {
              html
            }
          }
          meta {
            firstPublishedAt(formatString: "MMM D YYYY")
          }
          authors {
            name
            image {
              ...GatsbyDatoCmsImageCustom
            }
          }
          featureImage {
            ...GatsbyDatoCmsImageCustom
          }
        }
      }
    }
  `);
}
